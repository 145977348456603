import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams,projectId){
      return this.http.get(`${environment.baseUrl}/project/`+projectId+`/unit`,{params:searchParams});
    }

    get(id,projectId){
      return this.http.get(`${environment.baseUrl}/project/`+projectId+`/unit/`+id);
    }

    edit(id,formData,projectId){
      return this.http.put(`${environment.baseUrl}/project/`+projectId+`/unit/`+id,formData);
    }

    create(formData,projectId){
      return this.http.post(`${environment.baseUrl}/project/`+projectId+`/unit`,formData);
    }

    delete(id,projectId){
      return this.http.delete(`${environment.baseUrl}/project/`+projectId+`/unit/`+id);
    }


}
